<template>
  <section id="dashboard-usa-incidents">
    <b-card text-variant="center">
      <b-row class="row justify-content-between">
        <b-col class="col-auto">
          <p class="font-weight-bolder mt-0 mb-0">Latest GCP Incidents</p>
        </b-col>
        <b-col class="col-auto d-flex align-items-center gcp-text-justify">
          <b-row>
            <b-col class="col-auto">
              <feather-icon
                icon="CheckCircleIcon"
                size="16"
                class="feather-success"
              />
              <span class="legend"> Available </span>
            </b-col>
            <b-col class="col-auto">
              <feather-icon icon="InfoIcon" size="16" class="feather-info" />
              <span class="legend"> Service information</span>
            </b-col>
            <b-col class="col-auto">
              <feather-icon icon="InfoIcon" size="16" class="feather-warning" />
              <span class="legend"> Service disruption</span>
            </b-col>
            <b-col class="col-auto">
              <feather-icon
                icon="XCircleIcon"
                size="16"
                class="feather-danger"
              />
              <span class="legend"> Service outage</span>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
      <hr />
      <div
        v-for="i in updatePages.slice(10 * (currentPage - 1), 10 * currentPage)"
        :key="i.id"
        @click="viewGcpIncident(i.id)"
        class="cursor-pointer"
        v-show="!loading"
      >
        <b-row>
          <b-col lg="10" md="10" sm="12" xs="12">
            <p class="media-heading mb-0 font-weight-bolder gcp-text-justify">
              {{ i.title.slice(0, 70) }}
              [{{ i.modified.slice(0, 10) }}]
            </p>
            <p class="gcp-text-justify">{{ i.summary.slice(0, 155) }}</p>
          </b-col>
          <b-col
            lg="1"
            md="1"
            sm="6"
            xs="12"
            :class="
              i.severity === 'high'
                ? 'feather-danger'
                : i.severity === 'medium'
                ? 'feather-warning'
                : 'feather-info'
            "
            class="d-flex justify-content-center align-items-center"
          >
            <div>
              {{ i.severity.toUpperCase() }}
            </div>
          </b-col>
          <b-col
            lg="1"
            md="1"
            sm="6"
            xs="12"
            class="d-flex justify-content-center align-items-center"
          >
            <feather-icon
              v-if="i.status === 'AVAILABLE'"
              icon="CheckCircleIcon"
              size="16"
              class="feather-success"
            />

            <feather-icon
              v-if="i.status === 'SERVICE_INFORMATION'"
              icon="InfoIcon"
              size="16"
              class="feather-info"
            />
            <feather-icon
              v-if="i.status === 'SERVICE_DISRUPTION'"
              icon="InfoIcon"
              size="16"
              class="feather-info"
            />
            <feather-icon
              v-if="i.status === 'SERVICE_OUTAGE'"
              icon="XCircleIcon"
              size="16"
              class="feather-danger"
            />
          </b-col>
        </b-row>
        <hr />
      </div>
      <b-pagination
        v-model="currentPage"
        :total-rows="rows"
        :per-page="perPage"
        first-number
        last-number
        prev-class="prev-item"
        next-class="next-item"
        align="right"
      >
        <template #prev-text>
          <feather-icon icon="ChevronLeftIcon" size="18" />
        </template>
        <template #next-text>
          <feather-icon icon="ChevronRightIcon" size="18" />
        </template>
      </b-pagination>
    </b-card>
  </section>
</template>

<script>
import { BCard, BPagination, BRow, BCol } from "bootstrap-vue";
import "vue-loading-overlay/dist/vue-loading.css";
import "firebase/compat/database";
import Firebase from "firebase/compat/app";
import store from "@/store";

const database = Firebase.database().ref("/gcp_incidents");

export default {
  components: {
    BCard,
    BPagination,
    BRow,
    BCol,
  },
  mounted() {
    store.commit("appConfig/SPINNER_CONFIG", true);
    database.child("modified").once("value", (pages) => {
      store.commit("appConfig/SPINNER_CONFIG", false);
      pages.forEach((page) => {
        this.pages.push({
          ref: page.ref,
          id: page.child("id").val(),
          title: page.child("title").val(),
          summary: page.child("summary").val(),
          modified: page.child("modified").val(),
          severity: page.child("severity").val(),
          status: page.child("most_recent_update/status").val(),
        });
      });
    });
  },
  created() {
    const self = this;
    database.orderByChild("modified").on("child_removed", function (data) {
      const updatedPages = self.pages.filter((item) => item.id !== data.key);
      self.pages = updatedPages;
    });
    database.orderByChild("modified").on("child_added", function (data) {
      self.pages.push({
        ref: data.ref,
        id: data.child("id").val(),
        title: data.child("title").val(),
        summary: data.child("summary").val(),
        modified: data.child("modified").val(),
        severity: data.child("severity").val(),
        status: data.child("most_recent_update/status").val(),
      });
    });
    database.orderByChild("modified").on("child_changed", function (data) {
      const objIndex = self.pages.findIndex((obj) => obj.id == data.key);
      self.pages[objIndex].id = data.child("id").val();
      self.pages[objIndex].title = data.child("title").val();
      self.pages[objIndex].summary = data.child("summary").val();
      self.pages[objIndex].modified = data.child("modified").val();
      self.pages[objIndex].severity = data.child("severity").val();
      self.pages[objIndex].status = data
        .child("most_recent_update/status")
        .val();
    });
  },
  computed: {
    rows() {
      return this.pages.length;
    },
    updatePages() {
      return this.pages.reverse();
    },
  },
  data() {
    return {
      loading: false,
      pages: [],
      currentPage: 1,
      perPage: 10,
    };
  },
  methods: {
    viewGcpIncident(id) {
      window.open("https://status.cloud.google.com/incidents/" + id);
    },
  },
};
</script>
<style scoped>
.legend {
  margin-left: 3px;
  margin-right: 3px;
}
.gcp-text-justify {
  text-align: justify;
  margin: 0;
}
</style>
